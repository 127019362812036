import React, { useState } from "react";

import Modal from "./Modal";

import styles from "./formStyling.module.css";

function Talent({
    step,
    onNextClick,
    onBackClick,
    talentOptions,
    service,
    handleToggleTalent,
    selectedTalent,
    selectedPackages,
    selectedEnhancements,
    hours,
    enhancementValues,
}) {
    const [isModalOpen, setModalOpen] = useState(false);
    const [currentVideo, setCurrentVideo] = useState(null);
    const [sortby, setSortBy] = useState("popularity");

    const handleImageClick = (event, videoUrl) => {
        event.stopPropagation(); // Prevent the li onClick from firing

        // Add /embed correctly to the YouTube video URL
        const embedUrl = videoUrl.replace("watch?v=", "embed/");

        setCurrentVideo(embedUrl);
        setModalOpen(true);
    };

    const closeModal = () => {
        setModalOpen(false);
        setCurrentVideo(null);
    };

    // Calculate and sort talents based on their rating
    const sortedTalents = talentOptions
        .map((obj) => {
            // Calculate the average rating
            let rating =
                obj.reviews && obj.reviews.length > 0
                    ? obj.reviews.reduce((acc, item) => acc + item.overall, 0) /
                      obj.reviews.length
                    : 4; // Default to 4 stars if no reviews

            // Return the object with the rating
            return { ...obj, rating };
        })
        // Sort talents by rating in descending order
        .sort((a, b) => b.rating - a.rating);

    // Get the top 3 talents
    const top3Talents = sortedTalents.slice(0, 3);

    console.log(top3Talents, "top 3 talents");

    // Initialize a variable to track the highest price
    let highestPrice = 0;
    let highestRating = 0;

    // TODO: filter top 3 by talent availability in calendar. If none available, then use top 3 of all talent
    // Also filter talent by enhancement min and maxes.

    // Iterate over the top 3 talents to calculate the price
    top3Talents.forEach((obj) => {
        // Calculate the estimated price for the current talent
        let estimatedPrice = obj.packages.reduce((acc, item) => {
            if (selectedPackages.includes(item.id)) {
                let costingHours = hours - item.included_hours;
                if (costingHours < 0) {
                    costingHours = 0;
                }
                return acc + item.base_cost + costingHours * item.hourly_cost;
            } else {
                return acc;
            }
        }, 0);

        // Check if the current estimated price is higher than the current highestPrice
        if (estimatedPrice > highestPrice) {
            highestPrice = estimatedPrice;
        }

        if (obj.rating > highestRating) {
            highestRating = obj.rating;
        }
    });

    if (sortby === "price") {
        talentOptions.sort((a, b) => {
            let aPrice = a.packages.reduce((acc, item) => {
                if (selectedPackages.includes(item.id)) {
                    let costingHours = hours - item.included_hours;
                    if (costingHours < 0) {
                        costingHours = 0;
                    }
                    return (
                        acc + item.base_cost + costingHours * item.hourly_cost
                    );
                } else {
                    return acc;
                }
            }, 0);

            let bPrice = b.packages.reduce((acc, item) => {
                if (selectedPackages.includes(item.id)) {
                    let costingHours = hours - item.included_hours;
                    if (costingHours < 0) {
                        costingHours = 0;
                    }
                    return (
                        acc + item.base_cost + costingHours * item.hourly_cost
                    );
                } else {
                    return acc;
                }
            }, 0);

            return aPrice - bPrice;
        });
    } else {
        talentOptions.sort((a, b) => {
            let aRating =
                a.reviews && a.reviews.length > 0
                    ? a.reviews.reduce((acc, item) => acc + item.overall, 0) /
                      a.reviews.length
                    : 4;

            let bRating =
                b.reviews && b.reviews.length > 0
                    ? b.reviews.reduce((acc, item) => acc + item.overall, 0) /
                      b.reviews.length
                    : 4;

            return bRating - aRating;
        });
    }

    let TalentList = talentOptions.map((obj, index) => {
        // Get the rating stars
        let rating =
            obj.reviews && obj.reviews.length > 0
                ? Math.round(
                      obj.reviews.reduce((acc, item) => acc + item.overall, 0) /
                          obj.reviews.length
                  )
                : 4; // Default to 4 stars if no reviews

        let ratingName = "review_stars_" + rating;
        let stylesName = styles[ratingName];

        var videoSection = "";

        if (obj.video && obj.video.length > 0) {
            videoSection = (
                <img
                    src="/images/play.png"
                    alt="Play Button"
                    class={styles.talentPlayButton}
                    onClick={(event) => handleImageClick(event, obj.video)}
                />
            );
        }

        let estimatedPrice = 0;

        if (obj.packages.length > 0) {
            estimatedPrice += obj.packages.reduce((acc, item) => {
                if (selectedPackages.includes(item.id)) {
                    let costingHours = hours - item.included_hours;
                    if (costingHours < 0) {
                        costingHours = 0;
                    }
                    return (
                        acc + item.base_cost + costingHours * item.hourly_cost
                    );
                } else {
                    return acc;
                }
            }, 0);
        }

        if (obj.enhancements && obj.enhancements.length > 0) {
            estimatedPrice += obj.enhancements.reduce((acc, item) => {
                if (selectedEnhancements.includes(item.id)) {
                    let quantity = 1;
                    if (enhancementValues[item.id] !== undefined) {
                        quantity = enhancementValues[item.id];
                    }
                    return acc + item.rate * quantity;
                } else {
                    return acc;
                }
            }, 0);
        }

        return (
            <div
                className={
                    selectedTalent == obj.id
                        ? styles.selectedTalent
                        : styles.unselectedTalent
                }>
                <div
                    onClick={() => handleToggleTalent(obj.id)}
                    className={`${styles.accordion} ${styles.accordionExpanded}`}>
                    <div
                        className={`${styles.accordionSummary} ${styles.accordionSummaryExpanded}`}
                        aria-controls={"panel" + index + "-content"}
                        id={"panel" + index + "-header"}>
                        <div>
                            <strong>{obj.name}</strong>
                            <div class={styles.imageContainer150x150}>
                                <img
                                    src={
                                        process.env.REACT_APP_STORAGE_BASE_URL +
                                        obj.image
                                    }
                                    alt={obj.name}
                                    className={styles.talentImage}
                                />
                                {videoSection}
                            </div>
                            <span
                                className={`${styles.djreview} ${stylesName}`}></span>
                            <div>
                                <strong>Estimated Cost:</strong> $
                                {parseFloat(estimatedPrice).toFixed(2)}
                            </div>
                        </div>
                        <div
                            style={{ margin: "20px" }}
                            className={styles.talentSummary}>
                            <div style={{ height: "140px" }}>
                                {obj.short_description}
                            </div>
                            <div className={styles.talentButtons}>
                                <input
                                    className={styles.talentButton}
                                    value="View Profile"
                                    type="button"
                                    onClick={() => {
                                        window.location.href = obj.link;
                                    }}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    });

    // Create the special "Professional Match" object
    const professionalMatch = {
        id: 0, // Special case
        name: "Professional match",
        short_description:
            "We will talk with you and professionally select talent that matches your criteria.",
        image: "professional_match.jpg",
        rating: highestRating, // Use the highest rating of the top 3
        packages: [], // No packages for this special case
        reviews: [], // No reviews for this special case
        estimatedPrice: highestPrice, // Use the highest price from top 3 talents
        video: null, // Placeholder for future video
        link: null,
    };

    console.log(top3Talents.length, "top 3 talents length");
    console.log(highestPrice, "highest price");
    console.log(highestRating, "highest rating");

    // always include professional match
    //if (top3Talents.length > 0 && highestPrice > 0 && highestRating > 0) {

    if (highestRating == 0) highestRating = 4;
    if (highestPrice == 0) highestPrice = 3000; // Default to a reasonably high, $3000 if no talents are available

    // Get the rating stars
    let rating = professionalMatch.rating;
    let ratingName = "review_stars_" + rating;
    let stylesName = styles[ratingName];

    var videoSection = "";

    if (professionalMatch.video && professionalMatch.video.length > 0) {
        videoSection = (
            <img
                src="/images/play.png"
                alt="Play Button"
                class={styles.talentPlayButton}
                onClick={(event) =>
                    handleImageClick(event, professionalMatch.video)
                }
            />
        );
    }

    TalentList.unshift(
        <div
            className={
                selectedTalent === professionalMatch.id
                    ? styles.selectedTalent
                    : styles.unselectedTalent
            }>
            <div
                onClick={() => handleToggleTalent(professionalMatch.id)}
                className={`${styles.accordion} ${styles.accordionExpanded}`}>
                <div
                    className={`${styles.accordionSummary} ${styles.accordionSummaryExpanded}`}
                    aria-controls={"panel" + 0 + "-content"}
                    id={"panel" + 0 + "-header"}>
                    <div>
                        <strong>{professionalMatch.name}</strong>
                        <div class={styles.imageContainer150x150}>
                            <img
                                src="/images/professionalmatch.png"
                                alt={professionalMatch.name}
                                className={styles.talentImage}
                            />
                            {videoSection}
                        </div>
                        <span
                            className={`${styles.djreview} ${stylesName}`}></span>
                        <div>
                            <strong>Estimated Cost:</strong> $
                            {parseFloat(
                                professionalMatch.estimatedPrice
                            ).toFixed(2)}
                        </div>
                    </div>
                    <div
                        style={{ margin: "20px" }}
                        className={styles.talentSummary}>
                        <div style={{ height: "140px" }}>
                            {professionalMatch.short_description}
                        </div>
                        <div className={styles.talentButtons}>
                            <input
                                className={styles.talentButton}
                                value="View Profile"
                                type="button"
                                onClick={() => {
                                    window.location.href =
                                        professionalMatch.link;
                                }}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
    //}

    return (
        <div className={styles.wrap}>
            <h1>
                {service} Step {step} - Choose Talent
            </h1>
            <h3>
                Please choose which talent you would like to provide this
                service
            </h3>
            <hr />

            <div className={styles.sortByContainer}>
                <label className={styles.sortByLabel}>Sort By:</label>
                <div className={styles.sortByOptions}>
                    <label className={styles.sortByOption}>
                        <input
                            type="radio"
                            name="sortBy"
                            value="popularity"
                            defaultChecked
                            onChange={() => setSortBy("popularity")}
                        />
                        Popularity
                    </label>
                    <label className={styles.sortByOption}>
                        <input
                            type="radio"
                            name="sortBy"
                            value="price"
                            onChange={() => setSortBy("price")}
                        />
                        Price
                    </label>
                </div>
            </div>

            {/* Video modal */}
            <Modal
                isOpen={isModalOpen}
                onClose={closeModal}
                videoUrl={currentVideo}
            />

            <ul>
                {TalentList}
                <li>
                    <input
                        className={`${styles.buttondivided} ${styles.backButton}`}
                        type="button"
                        value="<< Go Back"
                        onClick={onBackClick}
                    />
                    <input
                        className={styles.buttondivided}
                        type="button"
                        value="Confirm Selected Talent >>"
                        onClick={onNextClick}
                    />
                </li>
            </ul>
        </div>
    );
}

export default Talent;
