import React, { useEffect, useState } from "react";

import axios from "axios";

import styles from "./formStyling.module.css";

function ChooseServices({
    onNextClick,
    onBackClick,
    step,
    services,
    toggleServiceClick,
    enabledServices,
    startingService,
    selectedEnhancements,
    selectedTalent, // New prop for selected talent
    enabledPackages, // New prop for enabled packages
    eventName,
    eventLocation,
    eventDate,
}) {
    const [OptionsList, setOptionsList] = useState([]);

    const [packagesData, setPackagesData] = useState([]);

    useEffect(() => {
        const fetchPackages = async () => {
            const response = await axios.get(
                process.env.REACT_APP_API_BASE_URL +
                    "packagesForService/" +
                    startingService
            );
            setPackagesData(response.data.packages);
        };

        fetchPackages();
    }, [startingService]);

    // Find the starting service object
    const startingServiceObj = services.find(
        (obj) => obj.id === startingService
    );

    useEffect(() => {
        // Create the options list for additional services
        const options = services.map((obj) => {
            if (obj.id === startingService) return ""; // Hide the starting service
            if (obj.packages.length === 0) return ""; // Hide services without packages

            return (
                <li
                    key={obj.id}
                    className={`${styles.ListItem} ${
                        styles.imageContainer240x160
                    } ${
                        enabledServices.includes(obj.id) ? styles.selected : ""
                    }`}>
                    <img
                        src={process.env.REACT_APP_STORAGE_BASE_URL + obj.image}
                        alt={obj.service}
                        className={styles.serviceImage}
                        onClick={toggleServiceClick}
                        value={obj.id}
                    />
                </li>
            );
        });

        setOptionsList(options);
    }, [enabledServices, services, toggleServiceClick, startingService]);

    if (typeof startingServiceObj === "undefined") return "";

    // Function to add the appropriate ordinal suffix to a day
    const getOrdinalSuffix = (day) => {
        if (day > 3 && day < 21) return "th"; // special case for 11th to 19th
        switch (day % 10) {
            case 1:
                return "st";
            case 2:
                return "nd";
            case 3:
                return "rd";
            default:
                return "th";
        }
    };

    // Function to format the date with day suffix
    const formatEventDate = (dateString) => {
        const date = new Date(dateString);
        const day = date.getDate();
        const dayWithSuffix = day + getOrdinalSuffix(day);

        const weekday = date.toLocaleDateString("en-US", { weekday: "long" });
        const month = date.toLocaleDateString("en-US", { month: "long" });
        const year = date.getFullYear();

        return `${weekday}, ${month} ${dayWithSuffix}, ${year}`;
    };

    let selectedPackage = {};
    if (selectedTalent.id == 0 || !selectedTalent.packages) {
        selectedPackage = packagesData.find(
            (selectedPackage) => selectedPackage.id == enabledPackages[0]
        );
    } else {
        selectedPackage = selectedTalent.packages.find((talentPackage) =>
            enabledPackages.includes(talentPackage.id)
        );
    }

    return (
        <div className={styles.wrap}>
            {/* Summary Section */}
            <div className={styles.summary}>
                <h1>Event Summary</h1>
                <div className={styles.summaryGrid}>
                    <div className={styles.summaryLabel}>
                        <strong>Event Name:</strong>
                    </div>
                    <div className={styles.summaryDetail}>{eventName}</div>

                    <div className={styles.summaryLabel}>
                        <strong>Event Location:</strong>
                    </div>
                    <div className={styles.summaryDetail}>{eventLocation}</div>

                    <div className={styles.summaryLabel}>
                        <strong>Event Date:</strong>
                    </div>
                    <div className={styles.summaryDetail}>
                        {formatEventDate(eventDate)}
                    </div>

                    <div className={styles.summaryLabel}>
                        <strong>Selected Service:</strong>
                    </div>
                    <div className={styles.summaryDetail}>
                        {startingServiceObj.service}
                    </div>

                    <div className={styles.summaryLabel}>
                        <strong>
                            Selected {startingServiceObj.talentDesignation}:
                        </strong>
                    </div>
                    <div className={styles.summaryDetail}>
                        {selectedTalent.name}
                    </div>

                    <div className={styles.summaryLabel}>
                        <strong>Packages Selected:</strong>
                    </div>
                    <div className={styles.summaryDetail}>
                        {selectedPackage ? selectedPackage.name : ""}
                    </div>

                    <div className={styles.summaryLabel}>
                        <strong>Enhancements Selected:</strong>
                    </div>
                    <div className={styles.summaryDetail}>
                        {startingServiceObj.enhancements
                            .filter((enhancement) =>
                                selectedEnhancements.includes(enhancement.id)
                            )
                            .map((enhancement) => enhancement.enhancement)
                            .join(", ")}
                    </div>
                </div>
            </div>

            {/* Original Choose Services Section */}
            <h2>Primary Service</h2>

            <div style={{ width: "100%" }}>
                <div
                    className={`${styles.centeredRow} ${styles.ListItem} ${styles.selected} ${styles.imageContainer300x200}`}>
                    <img
                        src={
                            process.env.REACT_APP_STORAGE_BASE_URL +
                            startingServiceObj.image
                        }
                        alt={startingServiceObj.service}
                        className={styles.serviceImage}
                    />
                </div>
            </div>

            <h3>
                What additional services are you looking for? (multiple
                allowed):{" "}
            </h3>
            <hr />

            <ul className={styles.List}>{OptionsList}</ul>

            {/* Navigation buttons */}
            <div className={styles.buttonContainer}>
                <input
                    className={`${styles.buttondivided} ${styles.backButton}`}
                    type="button"
                    value="<< Go Back"
                    onClick={onBackClick}
                />
                <input
                    className={styles.buttondivided}
                    type="button"
                    value="Next >>"
                    onClick={onNextClick}
                />
            </div>
        </div>
    );
}

export default ChooseServices;
