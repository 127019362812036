import React, { useEffect, useState, useRef } from "react";

import Modal from "./Modal";
import SerializeSlateToHtml from "./RichEditorSlateJS/SerializeSlateToHtml";

import styles from "./formStyling.module.css";

function ServiceEnhancements({
    selectedEnhancements,
    onEnhancementClick,
    onEnhancementChange,
    onBackClick,
    onNextClick,
    options,
    step,
    service,
}) {
    const nextButtonRef = useRef(null);
    const [OptionsList, setOptionsList] = useState("");
    const [isModalOpen, setModalOpen] = useState(false);
    const [currentVideo, setCurrentVideo] = useState(null);

    const handleImageClick = (event, videoUrl) => {
        event.stopPropagation(); // Prevent the li onClick from firing

        // Add /embed correctly to the YouTube video URL
        const embedUrl = videoUrl.replace("watch?v=", "embed/");

        setCurrentVideo(embedUrl);
        setModalOpen(true);
    };

    const closeModal = () => {
        setModalOpen(false);
        setCurrentVideo(null);
    };

    useEffect(() => {
        // Automatically click the button if the array is empty
        if (options.length === 0 && nextButtonRef.current) {
            //nextButtonRef.current.click();
        }

        setOptionsList(
            options.map((obj) => {
                var name = service + "_" + obj.id;
                var namedSection = "";
                var onClick = () => {
                    onEnhancementClick(obj.id);
                };
                var videoSection = "";

                if (obj.video.length > 0) {
                    videoSection = (
                        <img
                            src="/images/play.png"
                            alt="playbutton"
                            className={styles.playButton}
                            onClick={(event) =>
                                handleImageClick(event, obj.video)
                            }
                        />
                    );
                }

                if (obj.fk_enhancement_type !== 4) {
                    namedSection = (
                        <div className={styles.optionTitle}>
                            {obj.enhancement}
                        </div>
                    );
                } else {
                    if (obj.min !== null && obj.max !== null) {
                        onClick = () => {};

                        const adjustedMin = Math.max(obj.min, 0);

                        // create array from adjustedMin to max
                        const rangeOptions = Array.from(
                            { length: obj.max - adjustedMin + 1 },
                            (_, i) => i + adjustedMin
                        );

                        // Ensure 0 is included
                        if (!rangeOptions.includes(0)) {
                            rangeOptions.unshift(0); // Add 0 to the start of the options array
                        }

                        namedSection = (
                            <div className={styles.optionTitle}>
                                <select
                                    id={obj.id}
                                    name={name}
                                    className={styles.numberPicker}
                                    onChange={onEnhancementChange}>
                                    {rangeOptions.map((number) => (
                                        <option key={number} value={number}>
                                            {number}
                                        </option>
                                    ))}
                                </select>
                                {obj.enhancement}{" "}
                            </div>
                        );
                    }
                }

                return (
                    <li
                        key={obj.id}
                        style={{ padding: "6px" }}
                        className={`${styles.ListItem}  ${
                            selectedEnhancements.includes(obj.id)
                                ? styles.selected
                                : ""
                        }`}
                        onClick={onClick}>
                        <span
                            className={styles.optionName}
                            style={{
                                backgroundImage:
                                    "url('" +
                                    process.env.REACT_APP_STORAGE_BASE_URL +
                                    obj.image +
                                    "')",
                                height: "160px",
                                width: "240px",
                                backgroundSize: "cover",
                                color: "white",
                                fontSize: "20px",
                                textAlign: "center",
                                textShadow: "2px 2px 4px #000000",
                                margin: "0 auto",
                            }}>
                            {videoSection}
                        </span>

                        {namedSection}
                        <div className={styles.optionDescription}>
                            <SerializeSlateToHtml value={obj.description} />
                        </div>
                    </li>
                );
            })
        );
    }, [
        selectedEnhancements,
        onEnhancementClick,
        options,
        service,
        onEnhancementChange,
    ]);

    return (
        <div className={styles.wrap}>
            <h1>
                {service} Step {step} - Enhancements
            </h1>
            <h3>
                Please select any enhancements that you desire (optional -
                multiple allowed):{" "}
            </h3>
            <hr />
            <ul className={styles.List}>{OptionsList}</ul>

            {/* Video modal */}
            <Modal
                isOpen={isModalOpen}
                onClose={closeModal}
                videoUrl={currentVideo}
            />

            <ul>
                <li>
                    <input
                        className={`${styles.buttondivided} ${styles.backButton}`}
                        type="button"
                        value="<< Go Back"
                        onClick={onBackClick}
                    />
                    <input
                        className={styles.buttondivided}
                        type="button"
                        value="Next >>"
                        onClick={onNextClick}
                        ref={nextButtonRef}
                    />
                </li>
            </ul>
        </div>
    );
}

export default ServiceEnhancements;
