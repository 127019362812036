import React, { useEffect, useState } from "react";

import SignatureCanvas from "react-signature-canvas";

import styles from "./signature.module.css";

function Contract({ first, last, onNextClick, onBackClick, quoteData }) {
    const [signPads, setSignPads] = useState([]);
    const [ErrorMessage, setErrorMessage] = useState("");

    useEffect(() => {
        if (quoteData.services === undefined || quoteData.services === null)
            return;
        let pads = [];
        quoteData.services.forEach((service) => {
            pads[service.id] = {};
        });
        setSignPads(pads);
    }, [quoteData]);

    const clearPad = (id) => {
        if (
            signPads[id] !== undefined &&
            signPads[id] !== null &&
            signPads[id].clear !== undefined
        )
            signPads[id].clear();
    };

    const verifyPads = () => {
        let result = true;
        quoteData.services.forEach((service) => {
            console.log(service);
            console.log(signPads[service.id].isEmpty());

            if (signPads[service.id].isEmpty()) {
                setErrorMessage(
                    "Please sign all contracts before proceeding to payment."
                );
                result = false;
            }
        });
        if (result) onNextClick();
    };

    if (quoteData.services === undefined || quoteData.services === null)
        return <></>;

    return (
        <>
            <h1>Quote Contract</h1>
            <div className={styles.contractContainer}>
                {quoteData.services.map((service, index) => {
                    return (
                        <div key={index}>
                            <h1>{service.service} Booking Contract</h1>
                            <p className={styles.contractHeader}>
                                This contract is made between{" "}
                                <strong>On The Go DJ Pro</strong> ("DJ")
                                and&nbsp;
                                <strong>{first + " " + last}</strong> ("Client")
                                on this day,&nbsp;
                                <strong>
                                    {new Date().toLocaleDateString("en-US", {
                                        weekday: "long",
                                        year: "numeric",
                                        month: "long",
                                        day: "numeric",
                                    })}
                                </strong>
                                .
                            </p>

                            <h2>Terms and Conditions</h2>
                            <ol>
                                <li>
                                    <strong>Event Details:</strong> The Talent
                                    agrees to perform at the event on &nbsp;
                                    <strong>
                                        {new Date(
                                            quoteData.eventDate
                                        ).toLocaleDateString("en-US", {
                                            weekday: "long",
                                            year: "numeric",
                                            month: "long",
                                            day: "numeric",
                                        })}
                                    </strong>{" "}
                                    from <strong>{service.startTime}</strong> to
                                    &nbsp;
                                    <strong>{service.endTime}</strong>.
                                </li>
                                <li>
                                    <strong>Payment:</strong> The Client agrees
                                    to pay the Talent a total of &nbsp;
                                    <strong>${quoteData.total_price}</strong>. A
                                    deposit of{" "}
                                    <strong>${quoteData.deposit}</strong> is due
                                    upon signing this contract. The remaining
                                    balance is due on the day of the event.
                                </li>
                                <li>
                                    <strong>Cancellation:</strong> If the Client
                                    cancels the event within&nbsp;
                                    <strong>5</strong> days of the event date,
                                    the deposit is non-refundable.
                                </li>
                                <li>
                                    <strong>Equipment:</strong> The Talent will
                                    provide all necessary equipment for the
                                    performance, including sound system,
                                    microphones, and music library.
                                </li>
                                <li>
                                    <strong>Liability:</strong> The Talent is
                                    not responsible for any accidents or
                                    injuries that occur during the event. The
                                    Client agrees to indemnify and hold harmless
                                    the Talent from any claims arising from the
                                    event.
                                </li>
                                <li>
                                    <strong>Miscellaneous:</strong> This
                                    contract constitutes the entire agreement
                                    between the parties and may not be amended
                                    except in writing signed by both parties.
                                </li>
                            </ol>

                            <h2>Signatures</h2>
                            <div className={styles.signatureContainer}>
                                <div className={styles.clientSignatureBlock}>
                                    <p>
                                        <SignatureCanvas
                                            ref={(ref) => {
                                                signPads[service.id] = ref;
                                            }}
                                            penColor="green"
                                            canvasProps={{
                                                width: 800,
                                                height: 200,
                                                className: "sigCanvas",
                                            }}
                                        />
                                        <div className={styles.signatureLabel}>
                                            <strong>Client's Signature</strong>
                                        </div>
                                    </p>
                                    <button
                                        className={styles.clearButton}
                                        onClick={() => clearPad(service.id)}>
                                        Clear Signature
                                    </button>
                                </div>
                            </div>
                            <div style={{ fontSize: "11pt" }}>
                                Client Name:{" "}
                                <strong>{first + " " + last}</strong>
                            </div>
                            <div style={{ fontSize: "11pt" }}>
                                Date:{" "}
                                <strong>
                                    {new Date().toLocaleDateString("en-US")}
                                </strong>
                            </div>

                            <hr />
                        </div>
                    );
                })}

                <div style={{ fontWeight: "bold", color: "darkred" }}>
                    {ErrorMessage}
                </div>

                <input
                    className={`${styles.buttondivided} ${styles.backButton}`}
                    type="button"
                    value="<< Go Back"
                    onClick={onBackClick}
                />
                <input
                    className={styles.buttondivided}
                    type="submit"
                    value="Complete Contract & Proceed to Payment >>"
                    onClick={verifyPads}
                />
            </div>
        </>
    );
}

export default Contract;
