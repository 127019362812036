import React from "react";

import InputMask from "react-input-mask";

import styles from "./formStyling.module.css";

function ContactInfo({
    service,
    onNextClick,
    onBackClick,
    step,
    handleFirstChange,
    handleLastChange,
    handleEmailChange,
    handlePhoneChange,
    ErrorMessage,
}) {
    return (
        <div className={styles.wrap}>
            <h1>
                {service} Step {step} - Contact Information
            </h1>
            <h3>
                Please enter your name, email and phone number to see a list of
                qulified DJs in your area.
            </h3>
            <hr />
            <ul>
                <li>
                    <label for="name">
                        Your Name <span className={styles.required}>*</span>
                    </label>
                    <input
                        className={styles.fielddivided}
                        type="text"
                        id="first"
                        name="first"
                        onChange={handleFirstChange}
                        required
                    />
                    <input
                        className={styles.fielddivided}
                        type="text"
                        id="last"
                        name="last"
                        onChange={handleLastChange}
                        required
                    />
                </li>
                <li>
                    <label for="email">
                        Email <span className={styles.required}>*</span>
                    </label>
                    <input
                        className={styles.fieldLong}
                        type="email"
                        id="email"
                        name="email"
                        onChange={handleEmailChange}
                        required
                    />
                </li>
                <li>
                    <label for="phone">
                        Phone <span className={styles.required}>*</span>
                    </label>
                    <InputMask
                        mask="999-999-9999"
                        className={styles.fieldLong}
                        type="tel"
                        id="phone"
                        name="phone"
                        onChange={handlePhoneChange}
                        required
                    />
                </li>

                <li>
                    <div style={{ fontWeight: "bold", color: "darkred" }}>
                        {ErrorMessage}
                    </div>
                </li>

                <li>
                    <input
                        className={`${styles.buttondivided} ${styles.backButton}`}
                        type="button"
                        value="<< Go Back"
                        onClick={onBackClick}
                    />
                    <input
                        className={styles.buttondivided}
                        type="submit"
                        value="Next >>"
                        onClick={onNextClick}
                    />
                </li>
            </ul>
        </div>
    );
}

export default ContactInfo;
