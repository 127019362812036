import React, { useState, useEffect } from "react";

import axios from "axios";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";

import PaymentForm from "./PaymentForm";

import styles from "./payment.module.css";

const stripePromise = loadStripe(
    "pk_test_51Q2SnUK20d9LRB2Iv6P3aIMwL8ddnFgbdiKzTY5ZFutOQynJJfmyO9w61lugl0DhETo7DWAYNJMaYuOtDAYRw9Ry00MWw5m69A"
);

function Payment({ quoteData, onNextClick }) {
    const [clientSecret, setClientSecret] = useState("");
    const [selectedOption, setSelectedOption] = useState("full"); // 'full', 'downpayment', or 'custom'
    const [customAmount, setCustomAmount] = useState(quoteData.deposit); // Initialize with deposit
    const [paymentAmount, setPaymentAmount] = useState(quoteData.total_price); // Initialize with total price

    const [selectedMethod, setSelectedMethod] = useState("creditCard"); // To track the selected method

    const handleSelectMethod = (methodKey) => {
        setSelectedMethod(methodKey);
    };

    // Get a payment intent from the server
    useEffect(() => {
        if (!quoteData) return;
        if (!quoteData.id) return;

        const fetchPaymentIntent = async () => {
            try {
                const response = await axios.get(
                    process.env.REACT_APP_API_BASE_URL +
                        `quote/paymentIntent/${quoteData.id}?amount=${paymentAmount}`
                );
                console.log(response);
                setClientSecret(response.data.client_secret);
            } catch (error) {
                console.error("Error fetching payment intent:", error);
            }
        };
        fetchPaymentIntent();
    }, [quoteData, paymentAmount]);

    const appearance = {
        theme: "stripe",
    };

    const options = {
        clientSecret,
        appearance,
    };

    const handleOptionChange = (event) => {
        setSelectedOption(event.target.value);
        if (event.target.value === "custom") {
            setCustomAmount(quoteData.deposit); // Reset to deposit if custom is chosen
            setPaymentAmount(quoteData.deposit); // Reset to deposit if custom is chosen
        } else if (event.target.value === "downpayment") {
            setPaymentAmount(quoteData.deposit);
        } else {
            setPaymentAmount(quoteData.total_price);
        }
    };

    const handleCustomAmountChange = (event) => {
        let value = parseFloat(event.target.value);
        // Ensure the value stays between deposit and total_price
        if (value < quoteData.deposit) value = quoteData.deposit;
        if (value > quoteData.total_price) value = quoteData.total_price;
        setCustomAmount(value);
        setPaymentAmount(value); // Reset to deposit if custom is chosen
    };

    function formatArray(arr) {
        if (arr.length === 0) return "";
        if (arr.length === 1) return arr[0];
        if (arr.length === 2) return arr.join(" and ");

        // For arrays with more than 2 elements
        const allButLast = arr.slice(0, -1).join(", ");
        const lastElement = arr[arr.length - 1];

        return `${allButLast}, and ${lastElement}`;
    }

    const getSelectedAmount = () => {
        if (selectedOption === "full") return quoteData.total_price;
        if (selectedOption === "downpayment") return quoteData.deposit;
        return customAmount;
    };

    const paymentMethods = [
        {
            key: "creditCard",
            name: "Credit Card",
            image: "https://img.icons8.com/?size=100&id=22188&format=png&color=000000",
        },
        {
            key: "zelle",
            name: "Zelle",
            image: "https://img.icons8.com/?size=100&id=Iirw95F6Nl9c&format=png&color=000000",
        },
        {
            key: "check",
            name: "Check",
            image: "https://img.icons8.com/?size=100&id=13024&format=png&color=000000",
        },
        {
            key: "paypal",
            name: "PayPal",
            image: "https://img.icons8.com/?size=100&id=13611&format=png&color=000000",
        },
        {
            key: "venmo",
            name: "Venmo",
            image: "https://images.ctfassets.net/gkyt4bl1j2fs/ym6BkLqyGjMBmiCwtM7AW/829bf561ea771c00839b484cb8edeebb/App_Icon.png?w=276&h=276&q=50&fm=webp&bg=transparent",
        },
        {
            key: "phone",
            name: "Customer Service",
            image: "https://www.clker.com/cliparts/0/f/c/2/1195445181899094722molumen_phone_icon.svg.med.png",
        },
    ];

    if (!quoteData) return <></>;
    if (!quoteData.services) return;

    return (
        <>
            <h1>Confirm Payment</h1>
            <div className={styles.paymentContainer}>
                <h1>Payment Details</h1>
                <p className={styles.description}>
                    Please complete payment to confirm your booking for{" "}
                    {formatArray(
                        quoteData.services.map((service) => service.service)
                    )}{" "}
                    services. We offer a variety of payment options for your
                    convenience.
                </p>

                <div className={styles.paymentOption}>
                    <h2>Select Payment Amount</h2>
                    <div className={styles.paymentSelection}>
                        <ul>
                            <li>
                                <label>
                                    <input
                                        type="radio"
                                        value="full"
                                        checked={selectedOption === "full"}
                                        onChange={handleOptionChange}
                                    />
                                    Full Amount:{" "}
                                    <strong>
                                        $
                                        {quoteData.total_price.toLocaleString(
                                            "en-US",
                                            {
                                                maximumFractionDigits: 2,
                                                minimumFractionDigits: 2,
                                            }
                                        )}
                                    </strong>
                                </label>
                            </li>
                            <li>
                                <label>
                                    <input
                                        type="radio"
                                        value="downpayment"
                                        checked={
                                            selectedOption === "downpayment"
                                        }
                                        onChange={handleOptionChange}
                                    />
                                    Deposit:{" "}
                                    <strong>
                                        $
                                        {quoteData.deposit.toLocaleString(
                                            "en-US",
                                            {
                                                maximumFractionDigits: 2,
                                                minimumFractionDigits: 2,
                                            }
                                        )}
                                    </strong>
                                </label>
                            </li>
                            <li>
                                <label>
                                    <input
                                        type="radio"
                                        value="custom"
                                        checked={selectedOption === "custom"}
                                        onChange={handleOptionChange}
                                    />
                                    Custom Amount
                                    {selectedOption === "custom" && (
                                        <>
                                            : &nbsp;$
                                            <input
                                                type="number"
                                                min={quoteData.deposit}
                                                max={quoteData.total_price}
                                                value={customAmount}
                                                onChange={
                                                    handleCustomAmountChange
                                                }
                                            />
                                        </>
                                    )}
                                </label>
                            </li>
                        </ul>
                    </div>
                </div>

                <div className={styles.paymentOption}>
                    <h2>Available Payment Methods</h2>
                    <ul
                        style={{
                            listStyleType: "none",
                            paddingLeft: 0,
                            display: "flex",
                            justifyContent: "center",
                            gap: "10px",
                        }}>
                        {paymentMethods.map((method) => (
                            <li
                                key={method.key}
                                className={styles.paymentOption}
                                onClick={() => handleSelectMethod(method.key)}
                                style={{
                                    textAlign: "center",
                                    cursor: "pointer", // Change cursor to pointer to indicate it's clickable
                                    border:
                                        selectedMethod === method.key
                                            ? "3px solid #e28d01"
                                            : "3px solid transparent", // Add a blue border if selected
                                    padding: "10px", // Add padding for better click area
                                    borderRadius: "10px", // Optional, for rounded corners
                                    transition: "border 0.3s", // Smooth transition when selecting
                                }}>
                                <img
                                    src={method.image}
                                    alt={method.name}
                                    style={{
                                        width: "80px",
                                        height: "80px",
                                        display: "block",
                                        margin: "0 auto",
                                    }}
                                />
                                <label
                                    style={{
                                        display: "block",
                                        textAlign: "center",
                                        marginTop: "10px",
                                    }}>
                                    {method.name}
                                </label>
                            </li>
                        ))}
                    </ul>
                </div>

                <div className={styles.paymentOptions}>
                    {selectedMethod === "creditCard" && (
                        <div className={styles.paymentOption}>
                            <h3>Credit Card</h3>
                            {clientSecret && (
                                <Elements
                                    options={options}
                                    stripe={stripePromise}>
                                    <PaymentForm amount={getSelectedAmount()} />
                                </Elements>
                            )}
                        </div>
                    )}

                    {selectedMethod === "zelle" && (
                        <div className={styles.paymentOption}>
                            <h3>Zelle</h3>
                            <p>
                                Send payment to:{" "}
                                <strong>[Zelle Email/Phone]</strong>
                            </p>
                            <label>Payment Confirmation #:</label>
                            &nbsp;
                            <input type="text" />
                            <div style={{ marginTop: "10px" }}>
                                <button onClick={onNextClick}>
                                    I confirm payment has been sent
                                </button>
                            </div>
                        </div>
                    )}

                    {selectedMethod === "check" && (
                        <div className={styles.paymentOption}>
                            <h3>Check</h3>
                            <p>
                                Make payable to:{" "}
                                <strong>[Your Name/Company]</strong>
                            </p>
                            <p>
                                Mail to: <strong>[Your Address]</strong>
                            </p>
                            <label>Check #:</label>
                            &nbsp;
                            <input type="text" />
                            <div style={{ marginTop: "10px" }}>
                                <button onClick={onNextClick}>
                                    I confirm payment has been sent
                                </button>
                            </div>
                        </div>
                    )}

                    {selectedMethod === "paypal" && (
                        <div className={styles.paymentOption}>
                            <h3>PayPal</h3>
                            <p>
                                Send payment to: <strong>[PayPal Email]</strong>
                            </p>
                            <label>Payment Confirmation #:</label>
                            &nbsp;
                            <input type="text" />
                            <div style={{ marginTop: "10px" }}>
                                <button onClick={onNextClick}>
                                    I confirm payment has been sent
                                </button>
                            </div>
                        </div>
                    )}

                    {selectedMethod === "venmo" && (
                        <div className={styles.paymentOption}>
                            <h3>Venmo</h3>
                            <p>
                                Send payment to:{" "}
                                <strong>@[Venmo Username]</strong>
                            </p>
                            <label>Payment Confirmation #:</label>
                            &nbsp;
                            <input type="text" />
                            <div style={{ marginTop: "10px" }}>
                                <button onClick={onNextClick}>
                                    I confirm payment has been sent
                                </button>
                            </div>
                        </div>
                    )}

                    {selectedMethod === "phone" && (
                        <div className={styles.paymentOption}>
                            <h3>Customer Service</h3>
                            <p>
                                If you prefer to pay over the phone, please
                                contact our customer service at{" "}
                                <strong>[Customer Service Phone]</strong>.
                            </p>
                        </div>
                    )}
                </div>
            </div>
        </>
    );
}

export default Payment;
