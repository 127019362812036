import React from "react";

function TimePicker({ onTimeChange }) {
    const [CurrentHour, setCurrentHour] = React.useState("12"); // [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]
    const [CurrentMinute, setCurrentMinute] = React.useState("00");
    const [CurrentAmPm, setCurrentAmPm] = React.useState("PM");

    React.useEffect(() => {
        const sendTimeChange = () => {
            let localHour = CurrentHour;
            if (localHour === "12") localHour = 0;
            if (CurrentAmPm === "PM") localHour = parseInt(CurrentHour) + 12;

            onTimeChange(localHour + ":" + CurrentMinute);
        };

        sendTimeChange();
    }, [CurrentAmPm, CurrentHour, CurrentMinute, onTimeChange]);

    const onHourChange = ({ target }) => {
        setCurrentHour(target.value);
    };

    const onMinuteChange = ({ target }) => {
        setCurrentMinute(target.value);
    };

    const onAmPM = ({ target }) => {
        setCurrentAmPm(target.value);
    };

    return (
        <>
            <select required defaultValue="12" onChange={onHourChange}>
                <option>1</option>
                <option>2</option>
                <option>3</option>
                <option>4</option>
                <option>5</option>
                <option>6</option>
                <option>7</option>
                <option>8</option>
                <option>9</option>
                <option>10</option>
                <option>11</option>
                <option>12</option>
            </select>
            <select required onChange={onMinuteChange}>
                <option>00</option>
                <option>15</option>
                <option>30</option>
                <option>45</option>
            </select>
            <select required defaultValue="PM" onChange={onAmPM}>
                <option>AM</option>
                <option defaultChecked>PM</option>
            </select>
        </>
    );
}

export default TimePicker;
