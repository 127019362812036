import React from "react";

import DatePicker from "react-datepicker";
import GooglePlacesAutocomplete from "react-google-places-autocomplete";
import parse from "html-react-parser";

import "react-datepicker/dist/react-datepicker.css";
import styles from "./formStyling.module.css";

function EventInfo({
    onBackClick,
    onNextClick,
    onEventType,
    onClick,
    eventType,
    step,
    events,
    handleReferrerChange,
    startDate,
    handleStartDate,
    handleVenueNameChange,
    handleVenueAddressChange,
    handleLanguageChange,
    venueName,
    venueAddress,
    referrer,
    language,
    handleSecondaryLanguageChange,
    secondaryLanguage,
    languages,
    ErrorMessage,
    AddressErrorMessage,
    showEventNext,
}) {
    return (
        <div className={styles.wrap}>
            <h1>Event Information</h1>
            <h3>
                Please provide the following information so the system can
                calculate your quote
            </h3>
            <hr />
            <ul>
                <li>
                    <label>
                        Event Type <span className={styles.required}>*</span>
                    </label>
                    <select
                        style={{ width: "100%" }}
                        className={styles.fieldLong}
                        onChange={onEventType}
                        required
                        value={eventType}>
                        <option value="">Select Event Type</option>
                        {events.map((event) => {
                            return (
                                <option value={event.id}>
                                    {event.eventtype}
                                </option>
                            );
                        })}
                    </select>
                </li>
                <li>
                    <ul className={styles.List}>
                        {events.map((event) => {
                            return (
                                <li
                                    onClick={onClick}
                                    value={event.id}
                                    className={`${styles.ListItem} ${
                                        styles.imageContainer240x160
                                    } ${
                                        eventType == event.id
                                            ? styles.selected
                                            : ""
                                    }`}>
                                    <div className={styles.eventImageWrapper}>
                                        <img
                                            src={
                                                process.env
                                                    .REACT_APP_STORAGE_BASE_URL +
                                                event.image
                                            }
                                            alt={event.eventtype}
                                            value={event.id}
                                        />
                                        <div
                                            className={styles.eventTypeOverlay}>
                                            {event.eventtype}
                                        </div>
                                    </div>
                                </li>
                            );
                        })}
                    </ul>
                </li>

                <li>
                    <label>
                        Event Date <span className={styles.required}>*</span>
                    </label>
                    <DatePicker
                        wrapperClassName="datePicker"
                        showIcon
                        selected={startDate}
                        onChange={handleStartDate}
                    />
                </li>

                <li>
                    <label for="venueName">
                        Venue Name <span className={styles.required}>*</span>
                    </label>
                    &nbsp; * If this is a private residence, please enter the
                    homeowner's name <br />
                    <input
                        type="text"
                        style={{ width: "100%" }}
                        className={styles.fieldLong}
                        id="venueName"
                        name="venueName"
                        value={venueName}
                        onChange={handleVenueNameChange}
                        required
                    />
                </li>
                <li>
                    <label for="venueAddress">
                        Venue Address <span className={styles.required}>*</span>
                    </label>
                    &nbsp; * If no is venue selected, please choose the closest
                    zipcode/city
                    <br />
                    &nbsp; * If this is a private residence, please enter the
                    address
                    <br />
                    <GooglePlacesAutocomplete
                        autocompletionRequest={{
                            componentRestrictions: {
                                country: ["us"],
                            },
                        }}
                        apiKey={process.env.REACT_APP_CLOUD_API_KEY}
                        selectProps={{
                            defaultInputValue: venueAddress,
                            onChange: handleVenueAddressChange,
                        }}
                    />
                </li>

                <li>
                    <div style={{ fontWeight: "bold", color: "darkred" }}>
                        {parse(AddressErrorMessage)}
                    </div>
                </li>

                <li>
                    <label for="referrer">
                        How were you referred{" "}
                        <span className={styles.required}>*</span>
                    </label>
                    <select
                        style={{ width: "100%" }}
                        className={styles.fieldLong}
                        required
                        onChange={handleReferrerChange}
                        value={referrer}>
                        <option value="">Please Make Selection</option>
                        <option value="Google">Google</option>
                        <option value="WeddingWire.com">WeddingWire.com</option>
                        <option value="CA Bridal Expo (Los Angles)">
                            CA Bridal Expo (Los Angles)
                        </option>
                        <option value="CA Bridal Expo (Orange County)">
                            CA Bridal Expo (Orange County)
                        </option>
                        <option value="MSN">MSN</option>
                        <option value="Yahoo">Yahoo</option>
                        <option value="Yelp">Yelp</option>
                        <option value="Facebook">Facebook</option>
                        <option value="Bing">Bing</option>
                        <option value="The Knot">The Knot</option>
                        <option value="ProjectWedding.com">
                            ProjectWedding.com
                        </option>
                        <option value="MarthStewart.com">
                            MarthStewart.com
                        </option>
                        <option value="Brides.com">Brides.com</option>
                        <option value="WeddingBee.com">WeddingBee.com</option>
                        <option value="WeddingAces.com">WeddingAces.com</option>
                        <option value="BestCityWeddings.com">
                            BestCityWeddings.com
                        </option>
                        <option value="EventWire.com">EventWire.com</option>
                        <option value="BabyShowerWire.com">
                            BabyShowerWire.com
                        </option>
                        <option value="BirthdayWire.com">
                            BirthdayWire.com
                        </option>
                        <option value="MitzvahWire.com">MitzvahWire.com</option>
                        <option value="PromWire.com">PromWire.com</option>
                        <option value="Referral">Referral</option>
                        <option value="Returning Customer">
                            Returning Customer
                        </option>
                        <option value="Other">Other</option>
                    </select>
                </li>

                <li>
                    <label for="primarylanguage">
                        Primary Spoken Language{" "}
                        <span className={styles.required}>*</span>
                    </label>
                    <select
                        style={{ width: "100%" }}
                        className={styles.fieldLong}
                        required
                        onChange={handleLanguageChange}
                        value={language}>
                        {languages.map((language) => {
                            return (
                                <option value={language.id}>
                                    {language.name}
                                </option>
                            );
                        })}
                    </select>
                </li>

                <li>
                    <label for="secondarylanguage">
                        Secondary Spoken Language
                    </label>
                    <select
                        style={{ width: "100%" }}
                        className={styles.fieldLong}
                        required
                        onChange={handleSecondaryLanguageChange}
                        value={secondaryLanguage}>
                        <option value=""></option>
                        {languages.map((language) => {
                            return (
                                <option value={language.id}>
                                    {language.name}
                                </option>
                            );
                        })}
                    </select>
                </li>

                <li>
                    <div style={{ fontWeight: "bold", color: "darkred" }}>
                        {ErrorMessage}
                    </div>
                </li>

                <li>
                    <input
                        className={`${styles.buttondivided} ${styles.backButton}`}
                        type="button"
                        value="<< Go Back"
                        onClick={onBackClick}
                    />
                    <input
                        className={styles.buttondivided}
                        type="submit"
                        value="Next >>"
                        onClick={onNextClick}
                        style={{
                            display: showEventNext ? "inline-block" : "",
                        }}
                    />
                </li>
            </ul>
        </div>
    );
}

export default EventInfo;
