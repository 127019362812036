import * as React from "react";
import { Routes, Route, useNavigate, useLocation } from "react-router-dom";
import { geocodeByPlaceId } from "react-google-places-autocomplete";

import ContactInfo from "./ContactInfo";
import GetStarted from "./GetStarted";
import EventInfo from "./EventInfo";
import ServicePackages from "./ServicePackages";
import ServiceEnhancements from "./ServiceEnhancements";
import ChooseServices from "./ChooseServices";
import Talent from "./Talent";
import Product from "./Product";
import Quote from "./Quote";
import Contract from "./Contract";
import Payment from "./Payment";
import Complete from "./Complete";
import ChooseServicesAgain from "./ChooseServicesAgain";

export enum Screens {
    started = "started",
    event = "event",
    chooseServices = "choose-services",
    chooseServicesAgain = "choose-services-again",
    talent = "talent",
    product = "product",
    packages = "packages",
    booth = "booth",
    photography = "photography",
    serviceEnhancements = "service-enhancements",
    contact = "contact",
    quote = "quote",
    contract = "contract",
    payment = "payment",
    videography = "videography",
    venue = "venue",
    complete = "complete",
}

// Helper function to save state to localStorage
const saveToLocalStorage = (key, value) => {
    localStorage.setItem(key, JSON.stringify(value));
};

const actualLocalStorageVersion = "1";

// Helper function to get state from localStorage
const getFromLocalStorage = (key, defaultValue) => {
    console.log("getting from local storage", key, defaultValue);
    const savedValue = localStorage.getItem(key);
    return savedValue ? JSON.parse(savedValue) : defaultValue;
};

// Screen and section mappings
// const screenMappings = {
//     [Screens.started]: { section: 1, index: 1 },
//     [Screens.event]: { section: 1, index: 2 },
//     [Screens.chooseServices]: { section: 2, index: 1 },
//     [Screens.chooseServicesAgain]: { section: 2, index: 6 },
//     [Screens.talent]: { section: 2, index: 5 },
//     [Screens.product]: { section: 3, index: 1 },
//     [Screens.packages]: { section: 2, index: 2 },
//     [Screens.booth]: { section: 2, index: 2 },
//     [Screens.photography]: { section: 2, index: 2 },
//     [Screens.serviceEnhancements]: { section: 2, index: 3 },
//     [Screens.contact]: { section: 2, index: 4 },
//     [Screens.quote]: { section: 5, index: 1 },
//     [Screens.contract]: { section: 5, index: 2 },
//     [Screens.payment]: { section: 5, index: 3 },
//     [Screens.videography]: { section: 2, index: 2 },
//     [Screens.venue]: { section: 2, index: 2 },
// };

const serviceScreens = {
    1: Screens.packages,
    2: Screens.booth,
    3: Screens.photography,
    4: Screens.videography,
    5: Screens.venue,
};

// const afterServicesScreen = Screens.quote;
// const beforeServicesScreen = Screens.chooseServices;
const loadServices = [1, 2, 3, 4, 5];

let initServiceObj = [];

loadServices.forEach((serviceId) => {
    if (typeof initServiceObj[serviceId] === "undefined") {
        let localServiceObj = {
            selectedPrimary: [],
            selectedPackages: [],
            selectedEnhancements: [],
            enabledPackages: [],
            enhancementValues: [],
            filteredTalents: [],
            filteredOptions: [],
        };
        initServiceObj[serviceId] = localServiceObj;
    }
});

const ServicePackagesScreen = -1;

const beforeServicesScreens = {
    1: Screens.started,
    2: Screens.event,
};

const mainServiceScreens = {
    1: Screens.chooseServices,
    2: ServicePackagesScreen,
    3: Screens.serviceEnhancements,
    4: Screens.contact,
    5: Screens.talent,
    6: Screens.chooseServicesAgain,
};

const otherServiceScreensTalent = {
    1: ServicePackagesScreen,
    2: Screens.serviceEnhancements,
    3: Screens.talent,
};

const otherServiceScreensProduct = {
    1: Screens.product,
    2: ServicePackagesScreen,
    3: Screens.serviceEnhancements,
};

const afterServiceScreens = {
    1: Screens.quote,
    2: Screens.contract,
    3: Screens.payment,
};

const screenSections = {
    1: beforeServicesScreens,
    2: mainServiceScreens,
    3: otherServiceScreensTalent,
    4: otherServiceScreensProduct,
    5: afterServiceScreens,
};

function Quoting() {
    const navigate = useNavigate();
    const location = useLocation();

    React.useEffect(() => {
        const checkStorage = () => {
            if (
                localStorage.getItem("localStorageVersion") !==
                actualLocalStorageVersion
            ) {
                console.log("clearing storage");
                localStorage.clear();
                localStorage.setItem(
                    "localStorageVersion",
                    actualLocalStorageVersion
                );
                navigate(`/`);
            }
        };
        checkStorage();
    }, [navigate]);

    const navigateScreen = (nextScreen, screenSection, screenIndex) => {
        setScreenVisible(nextScreen);

        if (nextScreen === Screens.started) {
            navigate(`/`); // first screen has no path
        } else {
            navigate(`/${screenSection}/${screenIndex}/${nextScreen}`);
        }
    };

    const [ScreenVisible, setScreenVisible] = React.useState(() =>
        getFromLocalStorage("ScreenVisible", Screens.started)
    );
    const [currentScreenSection, setScreenSection] = React.useState(() =>
        getFromLocalStorage("currentScreenSection", 1)
    );
    const [currentScreenIndex, setScreenIndex] = React.useState(() =>
        getFromLocalStorage("currentScreenIndex", 1)
    );
    const [eventType, setEventType] = React.useState(() =>
        getFromLocalStorage("eventType", "")
    );
    const [startingService, setStartingService] = React.useState(() =>
        getFromLocalStorage("startingService", 1)
    );
    const [services, setServices] = React.useState(() =>
        getFromLocalStorage("services", [])
    );
    const [options, setOptions] = React.useState(() =>
        getFromLocalStorage("options", [])
    );
    const [selectedEnhancements, setSelectedEnhancements] = React.useState(() =>
        getFromLocalStorage("selectedEnhancements", [])
    );
    const [talents, setTalents] = React.useState(() =>
        getFromLocalStorage("talents", [])
    );
    const [products, setProducts] = React.useState(() =>
        getFromLocalStorage("products", [])
    );
    const [events, setEvents] = React.useState(() =>
        getFromLocalStorage("events", [])
    );
    const [serviceData, setServiceData] = React.useState(() =>
        getFromLocalStorage("serviceData", initServiceObj)
    );
    const [step, setStep] = React.useState(() =>
        getFromLocalStorage("step", 0)
    );
    const [currentService, setCurrentService] = React.useState(() =>
        getFromLocalStorage("currentService", 0)
    );
    const [currentServiceObj, setCurrentServiceObj] = React.useState(() =>
        getFromLocalStorage("currentServiceObj", {})
    );
    const [serviceName, setServiceName] = React.useState(() =>
        getFromLocalStorage("serviceName", "")
    );
    const [enabledServices, setEnabledServices] = React.useState(() =>
        getFromLocalStorage("enabledServices", [])
    );
    const [first, setFirst] = React.useState(() =>
        getFromLocalStorage("first", "")
    );
    const [last, setLast] = React.useState(() =>
        getFromLocalStorage("last", "")
    );
    const [email, setEmail] = React.useState(() =>
        getFromLocalStorage("email", "")
    );
    const [phone, setPhone] = React.useState(() =>
        getFromLocalStorage("phone", "")
    );
    const [venueName, setVenueName] = React.useState(() =>
        getFromLocalStorage("venueName", "")
    );
    const [venueAddress, setVenueAddress] = React.useState(() =>
        getFromLocalStorage("venueAddress", "")
    );
    const [apiInfo, setApiInfo] = React.useState(() =>
        getFromLocalStorage("apiInfo", {
            autocomplete: "",
            places: "",
            county: "",
        })
    );
    const [session, setSession] = React.useState(() =>
        getFromLocalStorage("session", "")
    );
    const [startDate, setStartDate] = React.useState(() =>
        getFromLocalStorage("startDate", new Date())
    );
    const [referrer, setReferrer] = React.useState(() =>
        getFromLocalStorage("referrer", "None")
    );
    const [weddingType, setWeddingType] = React.useState(() =>
        getFromLocalStorage("weddingType", "ceremony")
    );
    const [weddingTypeValue, setWeddingTypeValue] = React.useState(() =>
        getFromLocalStorage("weddingTypeValue", "Ceremony Only")
    );
    const [startTime, setStartTime] = React.useState(() =>
        getFromLocalStorage("startTime", "12:00")
    );
    const [endTime, setEndTime] = React.useState(() =>
        getFromLocalStorage("endTime", "12:00")
    );
    const [talent, setTalent] = React.useState(() =>
        getFromLocalStorage("talent", 0)
    );
    const [refreshQuote, setRefreshQuote] = React.useState(() =>
        getFromLocalStorage("refreshQuote", false)
    );
    const [selectedTalent, setSelectedTalent] = React.useState(() =>
        getFromLocalStorage("selectedTalent", {
            id: 0,
            name: "Professional Match",
        })
    );
    const [language, setLanguage] = React.useState(() =>
        getFromLocalStorage("language", "1")
    );
    const [secondaryLanguage, setSecondaryLanguage] = React.useState(() =>
        getFromLocalStorage("secondaryLanguage", "")
    );
    const [languages, setLanguages] = React.useState(() =>
        getFromLocalStorage("languages", [])
    );
    const [ErrorMessage, setErrorMessage] = React.useState(() =>
        getFromLocalStorage("ErrorMessage", "")
    );
    const [AddressErrorMessage, setAddressErrorMessage] = React.useState(() =>
        getFromLocalStorage("AddressErrorMessage", "")
    );
    const [hours, setHours] = React.useState(() =>
        getFromLocalStorage("hours", 0)
    );
    const [quoteData, setQuoteData] = React.useState(() =>
        getFromLocalStorage("quoteData", {})
    );
    const [counties, setCounties] = React.useState(() =>
        getFromLocalStorage("counties", [])
    );
    const [showEventNext, setShowEventNext] = React.useState(() =>
        getFromLocalStorage("showEventNext", false)
    );
    const [isAMEndTime, setIsAMEndTime] = React.useState(() =>
        getFromLocalStorage("isAMEndTime", false)
    );

    // Save state to localStorage whenever it changes
    React.useEffect(() => {
        saveToLocalStorage("ScreenVisible", ScreenVisible);
        saveToLocalStorage("eventType", eventType);
        saveToLocalStorage("startingService", startingService);
        saveToLocalStorage("services", services);
        saveToLocalStorage("options", options);
        saveToLocalStorage("selectedEnhancements", selectedEnhancements);
        saveToLocalStorage("talents", talents);
        saveToLocalStorage("products", products);
        saveToLocalStorage("events", events);
        saveToLocalStorage("serviceData", serviceData);
        saveToLocalStorage("step", step);
        saveToLocalStorage("currentService", currentService);
        saveToLocalStorage("currentServiceObj", currentServiceObj);
        saveToLocalStorage("serviceName", serviceName);
        saveToLocalStorage("enabledServices", enabledServices);
        saveToLocalStorage("first", first);
        saveToLocalStorage("last", last);
        saveToLocalStorage("email", email);
        saveToLocalStorage("phone", phone);
        saveToLocalStorage("venueName", venueName);
        saveToLocalStorage("venueAddress", venueAddress);
        saveToLocalStorage("apiInfo", apiInfo);
        saveToLocalStorage("session", session);
        saveToLocalStorage("startDate", startDate);
        saveToLocalStorage("referrer", referrer);
        saveToLocalStorage("weddingType", weddingType);
        saveToLocalStorage("weddingTypeValue", weddingTypeValue);
        saveToLocalStorage("startTime", startTime);
        saveToLocalStorage("endTime", endTime);
        saveToLocalStorage("talent", talent);
        saveToLocalStorage("refreshQuote", refreshQuote);
        saveToLocalStorage("selectedTalent", selectedTalent);
        saveToLocalStorage("language", language);
        saveToLocalStorage("secondaryLanguage", secondaryLanguage);
        saveToLocalStorage("languages", languages);
        saveToLocalStorage("ErrorMessage", ErrorMessage);
        saveToLocalStorage("hours", hours);
        saveToLocalStorage("quoteData", quoteData);
        saveToLocalStorage("counties", counties);
        saveToLocalStorage("AddressErrorMessage", AddressErrorMessage);
        saveToLocalStorage("showEventNext", showEventNext);
        saveToLocalStorage("isAMEndTime", isAMEndTime);
    }, [
        ScreenVisible,
        eventType,
        startingService,
        services,
        options,
        selectedEnhancements,
        talents,
        products,
        events,
        serviceData,
        step,
        currentService,
        currentServiceObj,
        serviceName,
        enabledServices,
        first,
        last,
        email,
        phone,
        venueName,
        venueAddress,
        apiInfo,
        session,
        startDate,
        referrer,
        weddingType,
        weddingTypeValue,
        startTime,
        endTime,
        talent,
        refreshQuote,
        selectedTalent,
        language,
        secondaryLanguage,
        languages,
        ErrorMessage,
        hours,
        quoteData,
        counties,
        AddressErrorMessage,
        showEventNext,
        isAMEndTime,
    ]);

    React.useEffect(() => {
        fetchCountyData();
    }, []);

    const fetchCountyData = async () => {
        try {
            fetch(process.env.REACT_APP_API_BASE_URL + `enabledCounties`)
                .then((response) => response.json())
                .then((data) => {
                    setCounties(data);
                });
        } catch (error) {
            console.error("Error fetching data:", error);
        }
    };

    // Listen for URL changes and update state accordingly
    React.useEffect(() => {
        const pathParts = location.pathname.split("/").filter(Boolean); // Split the path and filter out empty parts
        const section = pathParts[0] ? parseInt(pathParts[0]) : 1; // Default to section 1 if not available
        const index = pathParts[1] ? parseInt(pathParts[1]) : 1; // Default to index 1 if not available
        const screen = pathParts.length > 2 ? pathParts[2] : "started"; // Default to 'started' if not available

        /* original design didn't allow for multiple sections
        const path = location.pathname.replace('/', '');
        const screen = path ? path : Screens.started; // default to 'started' if the path is empty
        let { section, index } = screenMappings[screen] || { section: 1, index: 1 };
        */

        // Update state if the URL changes
        setScreenSection(section);
        setScreenIndex(index);
        setScreenVisible(screen);

        // Save to local storage
        saveToLocalStorage("currentScreenSection", section);
        saveToLocalStorage("currentScreenIndex", index);
        saveToLocalStorage("ScreenVisible", screen);
    }, [location]);

    // Save other states to localStorage when they change
    React.useEffect(() => {
        saveToLocalStorage("currentScreenSection", currentScreenSection);
        saveToLocalStorage("currentScreenIndex", currentScreenIndex);
    }, [currentScreenSection, currentScreenIndex]);

    const checkAmPM = () => {
        // check if starttime >= 12 and endtime < 12

        if (
            parseInt(startTime.split(":")[0]) >= 12 &&
            parseInt(endTime.split(":")[0]) < 12
        ) {
            setIsAMEndTime(true);
        } else {
            setIsAMEndTime(false);
        }
    };

    const handleStartTimeChange = (value) => {
        setStartTime(value);
        checkAmPM();
    };

    const handleEndTimeChange = (value) => {
        setEndTime(value);
        checkAmPM();
    };

    const onWeddingTypeChange = ({ target }) => {
        setWeddingTypeValue(target.value);
        if (target.value === "Reception Only") {
            setWeddingType("reception");
        } else {
            setWeddingType("ceremony");
        }
    };

    // Load services from the API
    React.useEffect(() => {
        if (services.length) return;
        loadServices.forEach((service) => {
            fetch(process.env.REACT_APP_API_BASE_URL + `services/${service}`)
                .then((response) => response.json())
                .then((responseData) => {
                    const data = responseData.data; // Access the service data inside the data key
                    setServices((prevServices) => {
                        prevServices[data.id] = data;
                        return prevServices.filter((x) => x);
                    });
                });
        });
    }, [services]);

    React.useEffect(() => {
        if (!startingService) return;

        fetch(
            process.env.REACT_APP_API_BASE_URL +
                `eventtypesForService/` +
                startingService
        )
            .then((response) => response.json())
            .then((data) => {
                setEvents(data.eventtypes);
            });
    }, [startingService, events.length]);

    React.useEffect(() => {
        if (typeof currentService === "undefined" || currentService === 0)
            return;
        if (typeof services[currentService] === "undefined") return;

        console.log("currentService", currentService);

        let currentServiceObj = services.find(
            (obj) => obj.id === currentService
        );

        // setting to a variable seems to help with updating in some cases
        var enhancements = currentServiceObj.enhancements;
        var selectedTalents = currentServiceObj.talents;
        var selectedProducts = currentServiceObj.products;
        var selectedLanguages = currentServiceObj.languages;

        console.log(enhancements, "enhancements");
        setOptions(enhancements);
        setTalents(selectedTalents);
        setProducts(selectedProducts);
        setLanguages(selectedLanguages);

        setServiceName(currentServiceObj.service);
        setCurrentServiceObj(currentServiceObj);
    }, [currentService, services]);

    React.useEffect(() => {
        if (!enabledServices.length) return;
        let localServiceData = serviceData;

        loadServices.forEach((serviceId) => {
            if (typeof localServiceData[serviceId] === "undefined") {
                let localServiceObj = {
                    selectedPrimary: [],
                    selectedPackages: [],
                    selectedEnhancements: [],
                    enabledPackages: [],
                    enhancementValues: [],
                    filteredTalents: [],
                    filteredOptions: [],
                };
                localServiceData[serviceId] = localServiceObj;
            }
        });

        if (localServiceData.length) setServiceData(localServiceData);
    }, [serviceData, enabledServices.length]);

    const getNextSection = (section) => {
        if ([2, 3, 4].indexOf(section) === -1) return section + 1;
        let nextService = getNextService(currentService);
        if (nextService === 0) {
            return 5;
        } else {
            setCurrentService(nextService);
            let localSeviceObj = services.find((obj) => obj.id === nextService);
            navigationSettingService = localSeviceObj.id;
            if (localSeviceObj.type === "person") {
                return 3;
            } else {
                return 4;
            }
        }
    };

    const getPreviousSection = (section) => {
        if ([3, 4, 5].indexOf(section) === -1) return section - 1;

        if (section === 5) {
            if (currentServiceObj.type === "person") {
                return 3;
            } else {
                return 4;
            }
        }
        let previousService = getPreviousService(currentService);
        if (previousService === 0) {
            setCurrentService(startingService);
            return 2;
        } else {
            setCurrentService(previousService);
            let localSeviceObj = services.find(
                (obj) => obj.id === previousService
            );
            navigationSettingService = localSeviceObj.id;
            if (localSeviceObj.type === "person") {
                return 3;
            } else {
                return 4;
            }
        }
    };

    const getNextService = (localCurrentService) => {
        let toSet = localCurrentService + 1;
        if (toSet === startingService) toSet++;
        if (toSet > enabledServices[enabledServices.length - 1]) {
            return 0;
        } else {
            while (toSet <= enabledServices[enabledServices.length - 1]) {
                if (enabledServices.indexOf(toSet) !== -1) return toSet;
                toSet++;
                if (toSet === startingService) toSet++;
            }
            return 0;
        }
    };

    const getPreviousService = (localCurrentService) => {
        let toSet = localCurrentService - 1;
        if (toSet === startingService) toSet--;
        if (toSet === 0) return 0;
        while (toSet > 0) {
            if (enabledServices.indexOf(toSet) !== -1) return toSet;
            toSet--;
            if (toSet === startingService) toSet--;
        }
        return 0;
    };

    let navigationSettingService = 0;

    const SaveEnabledServices = () => {
        fetch(
            process.env.REACT_APP_API_BASE_URL + `quote/saveenabledservices`,
            {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({
                    enabledservices: [startingService, ...enabledServices],
                    session: session,
                }),
            }
        )
            .then((response) => response.json())
            .then((data) => {
                console.log(data);
            });
    };

    const SaveEventInfo = () => {
        fetch(process.env.REACT_APP_API_BASE_URL + `quote/saveeventinfo`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                eventDate: startDate,
                referred_by: referrer,
                eventType: eventType,
                venueAddress: venueAddress,
                venueName: venueName,
                session: session,
                county: apiInfo.county,
                autocomplete: apiInfo.autocomplete,
                places: apiInfo.places,
                language: language,
                secondaryLanguage: secondaryLanguage,
            }),
        })
            .then((response) => response.json())
            .then((data) => {
                console.log(data);
            });
    };

    function getFloatHours(startTime, endTime) {
        // Split the time strings into hours and minutes
        const [startHours, startMinutes] = startTime.split(":").map(Number);
        const [endHours, endMinutes] = endTime.split(":").map(Number);

        // Convert start and end times to total minutes
        const startTotalMinutes = startHours * 60 + startMinutes;
        const endTotalMinutes = endHours * 60 + endMinutes;

        // Get the difference in minutes
        let differenceInMinutes = endTotalMinutes - startTotalMinutes;

        // If the difference is negative, it means endTime is on the next day
        if (differenceInMinutes < 0) {
            differenceInMinutes += 24 * 60; // Add 24 hours worth of minutes
        }

        // Convert the difference back into hours as a float
        const floatHours = differenceInMinutes / 60;

        return floatHours;
    }

    const SavePackageInfo = () => {
        let localServiceData = serviceData;
        let localServiceObj = localServiceData[currentService];

        // get hours between startTime and endTime
        setHours(getFloatHours(startTime, endTime));

        fetch(process.env.REACT_APP_API_BASE_URL + `quote/savepackageinfo`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                package: localServiceObj.selectedPackages[0],
                session: session,
                weddingType: weddingTypeValue,
                startTime: startTime,
                endTime: endTime,
            }),
        })
            .then((response) => response.json())
            .then((data) => {
                console.log(data);
            });
    };

    const SaveEnhancements = () => {
        let localServiceData = serviceData;
        let localServiceObj = localServiceData[currentService];
        fetch(process.env.REACT_APP_API_BASE_URL + `quote/saveenhancements`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                enhancements: localServiceObj.selectedEnhancements,
                session: session,
                enhancementValues: localServiceObj.enhancementValues,
            }),
        })
            .then((response) => response.json())
            .then((data) => {
                console.log(data);
            });
    };

    const SaveContactInfo = () => {
        fetch(process.env.REACT_APP_API_BASE_URL + `quote/savecontactinfo`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                first: first,
                last: last,
                email: email,
                phone: phone,
                session: session,
            }),
        })
            .then((response) => response.json())
            .then((data) => {
                console.log(data);
            });
    };

    const SaveTalent = () => {
        fetch(process.env.REACT_APP_API_BASE_URL + `quote/savetalent`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({ talent: talent, session: session }),
        })
            .then((response) => response.json())
            .then((data) => {
                console.log(data);
            });
    };

    const SaveEnabledProducts = () => {
        fetch(process.env.REACT_APP_API_BASE_URL + `quote/saveproducts`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                products: enabledProducts,
                session: session,
            }),
        })
            .then((response) => response.json())
            .then((data) => {
                console.log(data);
            });
    };

    const EventInfoErrorValidateCheck = (updateMessages) => {
        if (updateMessages) setErrorMessage("");
        if (!eventType) {
            if (updateMessages)
                setErrorMessage("You must select an event type.");
            return false;
        }
        if (!startDate) {
            if (updateMessages) setErrorMessage("You must select a date.");
            return false;
        }
        if (!venueName) {
            if (updateMessages) setErrorMessage("You must enter a venue name.");
            return false;
        }
        if (!venueAddress) {
            if (updateMessages)
                setErrorMessage("You must enter a venue address.");
            return false;
        }
        if (!referrer || referrer === "None") {
            if (updateMessages) setErrorMessage("You must select a referrer.");
            return false;
        }
        if (!language) {
            if (updateMessages) setErrorMessage("You must select a language.");
            return false;
        }

        if (
            apiInfo &&
            apiInfo.county &&
            apiInfo.county !== "" &&
            apiInfo.state &&
            apiInfo.state !== ""
        ) {
            if (!checkCounty(apiInfo.state, apiInfo.county, updateMessages)) {
                return false;
            }
        } else {
            return false;
        }

        return true;
    };

    const handleNextClick = () => {
        console.log("next click");
        console.log("currentScreenSection", currentScreenSection);
        console.log("currentScreenIndex", currentScreenIndex);

        switch (ScreenVisible) {
            case Screens.event:
                if (EventInfoErrorValidate(true)) SaveEventInfo();

                break;
            case Screens.chooseServices:
            case Screens.chooseServicesAgain:
                SaveEnabledServices();
                break;
            case Screens.packages:
            case Screens.booth:
            case Screens.photography:
            case Screens.videography:
            case Screens.venue:
                if (serviceData[currentService].selectedPackages.length === 0)
                    return; // you must select a package.
                SavePackageInfo();
                break;
            case Screens.product:
                if (enabledProducts.length === 0) return; // you must select at least one product.
                SaveEnabledProducts();
                break;
            case Screens.serviceEnhancements:
                SaveEnhancements();
                break;

            case Screens.contact:
                console.log(first);
                setErrorMessage("");
                if (!first) {
                    setErrorMessage("You must enter a first name.");
                    return;
                }
                if (!last) {
                    setErrorMessage("You must enter a last name.");
                    return;
                }
                if (!email) {
                    setErrorMessage("You must enter an email address.");
                    return;
                }
                if (!phone) {
                    setErrorMessage("You must enter a phone number.");
                    return;
                }
                SaveContactInfo();
                break;
            case Screens.talent:
                SaveTalent();
                break;
        }

        let localScreenIndex = currentScreenIndex + 1;
        let localScreenSection = currentScreenSection;
        navigationSettingService = currentServiceObj.id;

        if (
            localScreenIndex >
            Object.keys(screenSections[currentScreenSection]).length
        ) {
            localScreenIndex = 1;
            localScreenSection = getNextSection(currentScreenSection);
            console.log("setting section to ", localScreenSection);
            setScreenSection(localScreenSection);
        }

        if (localScreenSection === 2) {
            setStep(localScreenIndex - 1);
        } else {
            setStep(localScreenIndex);
        }
        console.log("setting screen index to ", localScreenIndex);
        setScreenIndex(localScreenIndex);

        if (
            screenSections[localScreenSection][localScreenIndex] ===
            ServicePackagesScreen
        ) {
            navigateScreen(
                serviceScreens[navigationSettingService],
                localScreenSection,
                localScreenIndex
            );
            //setScreenVisible(serviceScreens[navigationSettingService])
        } else {
            if (
                screenSections[localScreenSection][localScreenIndex] ===
                Screens.quote
            ) {
                setRefreshQuote(true);
            }

            if (
                screenSections[localScreenSection][localScreenIndex] ===
                Screens.serviceEnhancements
            ) {
                var filteredOptions = serviceData[currentServiceObj.id]
                    ? serviceData[currentServiceObj.id].filteredOptions
                    : [];
                if (filteredOptions.length === 0) {
                    localScreenIndex++;
                    setScreenIndex(localScreenIndex);
                }
            }

            navigateScreen(
                screenSections[localScreenSection][localScreenIndex],
                localScreenSection,
                localScreenIndex
            );
            //setScreenVisible(screenSections[localScreenSection][localScreenIndex]);
        }
    };

    const handleBackClick = () => {
        console.log("back click");
        console.log("currentScreenSection", currentScreenSection);
        console.log("currentScreenIndex", currentScreenIndex);

        let localScreenIndex = currentScreenIndex - 1;
        let localScreenSection = currentScreenSection;
        navigationSettingService = currentServiceObj.id;

        if (localScreenIndex === 0) {
            localScreenSection = getPreviousSection(currentScreenSection);
            localScreenIndex = Object.keys(
                screenSections[localScreenSection]
            ).length;
            setScreenSection(localScreenSection);
        }

        if (localScreenSection === 2) {
            setStep(localScreenIndex - 1);
        } else {
            setStep(localScreenIndex);
        }
        setScreenIndex(localScreenIndex);
        if (
            screenSections[localScreenSection][localScreenIndex] ===
            ServicePackagesScreen
        ) {
            navigateScreen(
                serviceScreens[navigationSettingService],
                localScreenSection,
                localScreenIndex
            );
            //setScreenVisible(serviceScreens[navigationSettingService])
        } else {
            if (
                screenSections[localScreenSection][localScreenIndex] ===
                Screens.serviceEnhancements
            ) {
                var filteredOptions = serviceData[currentServiceObj.id]
                    ? serviceData[currentServiceObj.id].filteredOptions
                    : [];
                if (filteredOptions.length === 0) {
                    console.log(localScreenIndex);
                    localScreenIndex--;
                    console.log(localScreenIndex);
                    setScreenIndex(localScreenIndex);
                }
            }

            if (
                screenSections[localScreenSection][localScreenIndex] ===
                ServicePackagesScreen
            ) {
                navigateScreen(
                    serviceScreens[navigationSettingService],
                    localScreenSection,
                    localScreenIndex
                );
            } else {
                navigateScreen(
                    screenSections[localScreenSection][localScreenIndex],
                    localScreenSection,
                    localScreenIndex
                );
            }

            //setScreenVisible(screenSections[localScreenSection][localScreenIndex]);
            if (
                screenSections[localScreenSection][localScreenIndex] ===
                Screens.quote
            ) {
                setRefreshQuote(true);
            }
        }
    };

    const handleEventType = ({ target }) => {
        let eventTypeId = target.value;
        filterEnhancements(eventTypeId);
        setEventType(eventTypeId);
    };

    const handleOnClick = ({ target }) => {
        let eventTypeId = target.getAttribute("value");
        filterEnhancements(eventTypeId);
        setEventType(eventTypeId);
    };

    const filterEnhancements = (eventTypeId) => {
        const newServiceData = serviceData.map((serviceObj, index) => {
            if (index === 0) return undefined; // Skip the first element

            // Filter the enhancements based on the event type for each service object

            const newFilteredOptions = (
                services.find((service) => service.id === index)
                    ?.enhancements || []
            ).filter((obj) =>
                (
                    obj.event_types?.map((event_type) => event_type.id) || []
                ).includes(parseInt(eventTypeId))
            );

            // Return the updated service object with filteredOptions
            return {
                ...serviceObj,
                filteredOptions: newFilteredOptions,
            };
        });

        // Update the state with the modified service data
        setServiceData([...newServiceData]);
    };

    const toggleServiceClick = ({ target }) => {
        let i = parseInt(target.getAttribute("value"));
        if (enabledServices.indexOf(i) === -1) {
            setEnabledServices(
                enabledServices
                    .filter((f) => f !== i)
                    .concat([i])
                    .sort()
            );
        } else {
            setEnabledServices(enabledServices.filter((f) => f !== i));
        }
    };

    const handleFirstChange = ({ target }) => {
        setFirst(target.value);
    };
    const handleLastChange = ({ target }) => {
        setLast(target.value);
    };

    const handleEmailChange = ({ target }) => {
        setEmail(target.value);
    };
    const handlePhoneChange = ({ target }) => {
        setPhone(target.value);
    };

    const handleVenueNameChange = ({ target }) => {
        setVenueName(target.value);
        EventInfoErrorValidate(false);
    };

    const EventInfoErrorValidate = (updateMessages) => {
        let result = EventInfoErrorValidateCheck(updateMessages);

        setShowEventNext(result);
        return result;
    };

    React.useEffect(() => {
        EventInfoErrorValidate(false);
    }, [
        venueName,
        apiInfo,
        eventType,
        EventInfoErrorValidate,
        referrer,
        startDate,
        language,
    ]);

    const handleVenueAddressChange = async (obj) => {
        apiInfo.autocomplete = JSON.stringify(obj);

        console.log(obj);
        setVenueAddress(obj.label);
        const results = await geocodeByPlaceId(obj.value.place_id);
        console.log("Google Places API Response:", results); // Log the entire JSON response
        apiInfo.places = JSON.stringify(results);

        // Extract and log the County information
        const addressComponents = results[0].address_components;
        const county = addressComponents.find((component) =>
            component.types.includes("administrative_area_level_2")
        );

        apiInfo.county = county;
        // extract state information
        const state = addressComponents.find((component) =>
            component.types.includes("administrative_area_level_1")
        );

        apiInfo.state = state;
        if (county) {
            console.log("County:", county.long_name);
            apiInfo.county = county.long_name;
        } else {
            apiInfo.county = "County information not found";
            console.log("County information not found");
        }
        setApiInfo(apiInfo);

        checkCounty(apiInfo.state, apiInfo.county, true);
    };

    const checkCounty = (venueState, venueCounty, updateMessages) => {
        if (updateMessages) setAddressErrorMessage("");

        if (
            !counties
                .map((state) => state.state.toLowerCase())
                .includes(venueState.long_name.toLowerCase())
        ) {
            if (updateMessages)
                setAddressErrorMessage(
                    "It appears that the Venue you have chosen is not in our service area."
                );
            return false;
        }

        if (
            !counties
                .find(
                    (state) =>
                        state.state.toLowerCase() ===
                        venueState.long_name.toLowerCase()
                )
                .counties.map((county) => county.county)
                .includes(venueCounty)
        ) {
            let message =
                "It appears that the Venue you have chosen is not in our service area. <br /><br />We do service the following counties in the state of " +
                venueState.long_name +
                ": ";

            counties
                .find(
                    (state) =>
                        state.state.toLowerCase() ===
                        venueState.long_name.toLowerCase()
                )
                .counties.forEach((county) => {
                    message += county.county + ", ";
                });

            if (message.endsWith(", ")) message = message.slice(0, -2);

            console.log(services[startingService]);

            message +=
                "<br /><br />If you have any questions or feel you have reached this in error, please contact us at " +
                services[startingService].phone_number +
                ".";

            if (updateMessages) setAddressErrorMessage(message);
            return false;
        }
        return true;
    };

    const handleRestartClick = () => {
        navigateScreen(Screens.started, 1, 1);

        //setScreenVisible(Screens.event);
        setStep(1);
        setCurrentService(startingService);
        setEventType("");
        setFirst("");
        setLast("");
        setEmail("");
        setPhone("");
        setVenueName("");

        setEnabledProducts([]);
        setServiceData(initServiceObj);
        setEnabledServices([]);
        setScreenSection(1);
        setScreenIndex(1);
        setSelectedEnhancements([]);
        setSession("");
    };

    const handleEditClick = () => {
        navigateScreen(Screens.event, 1, 2);

        //setScreenVisible(Screens.event);
        setStep(1);
        setCurrentService(startingService);
        setScreenSection(1);
        setScreenIndex(1);
    };

    const handleBookClick = (total) => {
        document.querySelector(".App-body").scrollTo(0, 0);

        // post to server that the quote is booked
        fetch(process.env.REACT_APP_API_BASE_URL + `quote/book`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({ session: session, total: total }),
        })
            .then((response) => response.json())
            .then((data) => {
                setQuoteData(data.quote);
            });

        //setScreenVisible(Screens.contract);
        navigateScreen(Screens.contract, 5, 2);
    };

    const handleContractClick = () => {
        navigateScreen(Screens.payment, 5, 3);
        //setScreenVisible(Screens.payment);
    };

    const toQuoteClick = () => {
        navigateScreen(Screens.quote, 5, 1);
        //setScreenVisible(Screens.quote);
    };

    const handleQuoteClick = (value) => {
        let service = parseInt(value);
        console.log("previous service: ", startingService);
        console.log("starting service: ", service);
        setStartingService(service);
        setCurrentService(service);

        // post to the server to create the new quote.
        fetch(process.env.REACT_APP_API_BASE_URL + `newquote`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({ service: service }),
        })
            .then((response) => response.json())
            .then((data) => {
                setSession(data.session);
            });

        handleNextClick();
    };

    const handlePackageToggle = (value) => {
        console.log("package toggle " + value);
        console.log("update");
        let localServiceData = serviceData;
        let localServiceObj = localServiceData[currentService];
        if (localServiceObj.selectedPackages.indexOf(value) === -1) {
            localServiceObj.selectedPackages = [value];
        } else {
            localServiceObj.selectedPackages =
                localServiceObj.selectedPackages.filter((f) => f !== value);
        }

        let service = services.find((service) => service.id == currentService);
        console.log(localServiceObj.selectedPackages, "selected packages");
        console.log(service.talents);

        let selectedTalents = service.talents.filter((talent) =>
            talent.packages.some((talentPackage) =>
                localServiceObj.selectedPackages.includes(talentPackage.id)
            )
        );

        console.log(currentService, "current service");
        console.log(
            services.find((service) => service.id == currentService).talents,
            "current service talents"
        );
        console.log(services, "services");
        console.log(selectedTalents, "selected Talents");

        console.log(apiInfo.county.toLowerCase(), "county");

        selectedTalents = selectedTalents.filter((talent) =>
            talent.service_areas.some(
                (service_area) =>
                    service_area.county.toLowerCase() ==
                    apiInfo.county.toLowerCase()
            )
        );

        console.log("after filter by county", selectedTalents);

        selectedTalents = selectedTalents.filter((talent) =>
            talent.languages.some(
                (talentLanguage) => talentLanguage.id == language
            )
        );

        console.log("after filter by language", selectedTalents);

        localServiceObj.filteredTalents = selectedTalents;

        localServiceData[currentService] = localServiceObj;
        setServiceData([...localServiceData]);
    };

    // const [enabledDJPackages, setEnabledDJPackages] = React.useState([]);

    // const handleDJPackageToggle = (value) => {
    //     if (enabledDJPackages.indexOf(value) === -1) {
    //         setEnabledDJPackages([value]);
    //     } else {
    //         setEnabledDJPackages(enabledDJPackages.filter((f) => f !== value));
    //     }
    // };

    // const [enabledPhotographyPackages, setEnabledPhotographyPackages] =
    //     React.useState([]);

    // const handlePhotographyPackageToggle = (value) => {
    //     if (enabledPhotographyPackages.indexOf(value) === -1) {
    //         setEnabledPhotographyPackages([value]);
    //     } else {
    //         setEnabledPhotographyPackages(
    //             enabledPhotographyPackages.filter((f) => f !== value)
    //         );
    //     }
    // };

    // const [enabledVideographyPackages, setEnabledVideographyPackages] =
    //     React.useState([]);

    // const handleVideographyPackageToggle = (value) => {
    //     if (enabledVideographyPackages.indexOf(value) === -1) {
    //         setEnabledVideographyPackages([value]);
    //     } else {
    //         setEnabledVideographyPackages(
    //             enabledVideographyPackages.filter((f) => f !== value)
    //         );
    //     }
    // };

    // const [enabledPhotoBoothPackages, setEnabledPhotoBoothPackages] =
    //     React.useState([]);

    // const handlePhotoBoothPackageToggle = (value) => {
    //     if (enabledPhotoBoothPackages.indexOf(value) === -1) {
    //         setEnabledPhotoBoothPackages([value]);
    //     } else {
    //         setEnabledPhotoBoothPackages(
    //             enabledPhotoBoothPackages.filter((f) => f !== value)
    //         );
    //     }
    // };

    // const [enabledVenuePackages, setEnabledVenuePackages] = React.useState([]);

    // const handleVenuePackageToggle = (value) => {
    //     if (enabledVenuePackages.indexOf(value) === -1) {
    //         setEnabledVenuePackages([value]);
    //     } else {
    //         setEnabledVenuePackages(
    //             enabledVenuePackages.filter((f) => f !== value)
    //         );
    //     }
    // };

    const [enabledProducts, setEnabledProducts] = React.useState([]);
    const handleProductToggle = (value) => {
        console.log(value);
        if (enabledProducts.indexOf(value) === -1) {
            setEnabledProducts([...enabledProducts, value]);
        } else {
            setEnabledProducts(enabledProducts.filter((f) => f !== value));
        }
    };

    const handleEnhancementToggle = (value) => {
        let localServiceData = serviceData;
        let localServiceObj = localServiceData[currentService];
        if (localServiceObj.selectedEnhancements.indexOf(value) === -1) {
            localServiceObj.selectedEnhancements.push(value);
        } else {
            localServiceObj.selectedEnhancements =
                localServiceObj.selectedEnhancements.filter((f) => f !== value);
        }
        localServiceData[currentService] = localServiceObj;
        setServiceData([...localServiceData]);

        setSelectedEnhancements([...localServiceObj.selectedEnhancements]); // force a re-render
    };

    const handleEnhancementChange = ({ target }) => {
        let localServiceData = serviceData;
        let localServiceObj = localServiceData[currentService];
        if (target.value == 0) {
            // remove target.id from enhancements
            localServiceObj.selectedEnhancements =
                localServiceObj.selectedEnhancements.filter(
                    (f) => f !== parseInt(target.id)
                );

            localServiceObj.enhancementValues =
                localServiceObj.enhancementValues.filter(
                    (f) => f.id !== parseInt(target.id)
                );
        } else {
            // add target.id to enhancements if not already there
            if (
                localServiceObj.selectedEnhancements.indexOf(
                    parseInt(target.id)
                ) === -1
            ) {
                localServiceObj.selectedEnhancements.push(parseInt(target.id));
            }
            if (
                localServiceObj.enhancementValues.filter(
                    (f) => f.id === parseInt(target.id)
                ).length === 0
            ) {
                localServiceObj.enhancementValues.push({
                    id: parseInt(target.id),
                    value: parseInt(target.value),
                });
            } else {
                localServiceObj.enhancementValues.find(
                    (f) => f.id === parseInt(target.id)
                ).value = parseInt(target.value);
            }
        }

        localServiceData[currentService] = localServiceObj;
        setServiceData([...localServiceData]);
        setSelectedEnhancements([...localServiceObj.selectedEnhancements]); // force a re-render
    };

    const handleStartDate = (target) => {
        setStartDate(target);
    };

    const handleReferrerChange = ({ target }) => {
        setReferrer(target.value);
    };

    const handleToggleTalent = (id) => {
        if (talent == id) {
            setTalent(0);
            setSelectedTalent({});
        } else {
            setTalent(id);
            if (id === 0) {
                setSelectedTalent({ id: 0, name: "Professional Match" });
            } else {
                setSelectedTalent(talents.find((talent) => talent.id === id));
            }
        }
    };

    const handleLanguageChange = ({ target }) => {
        setLanguage(target.value);
    };

    const handleSecondaryLanguageChange = ({ target }) => {
        setSecondaryLanguage(target.value);
    };

    const handlePaymentDone = () => {
        navigate(`/complete`);
    };

    return (
        <Routes>
            <Route
                path="/"
                element={
                    <GetStarted
                        onStartClick={handleQuoteClick}
                        services={services}
                    />
                }
            />
            <Route
                path={`/:section/:index/${Screens.chooseServices}`}
                element={
                    <ChooseServices
                        startingService={startingService}
                        onBackClick={handleBackClick}
                        onNextClick={handleNextClick}
                        toggleServiceClick={toggleServiceClick}
                        step={step}
                        services={services}
                        enabledServices={enabledServices}
                    />
                }
            />
            <Route
                path={`/:section/:index/${Screens.chooseServicesAgain}`}
                element={
                    <ChooseServicesAgain
                        startingService={startingService}
                        onBackClick={handleBackClick}
                        onNextClick={handleNextClick}
                        toggleServiceClick={toggleServiceClick}
                        step={step}
                        services={services}
                        enabledPackages={
                            serviceData[startingService].selectedPackages
                        }
                        enabledServices={enabledServices}
                        selectedTalent={selectedTalent}
                        eventName={venueName}
                        eventLocation={venueAddress}
                        eventDate={startDate}
                        selectedEnhancements={selectedEnhancements}
                    />
                }
            />
            <Route
                path={`/:section/:index/${Screens.event}`}
                element={
                    <EventInfo
                        onBackClick={handleBackClick}
                        onNextClick={handleNextClick}
                        onClick={handleOnClick}
                        onEventType={handleEventType}
                        eventType={eventType}
                        step={step}
                        events={events}
                        startDate={startDate}
                        handleReferrerChange={handleReferrerChange}
                        handleStartDate={handleStartDate}
                        handleVenueAddressChange={handleVenueAddressChange}
                        handleVenueNameChange={handleVenueNameChange}
                        handleLanguageChange={handleLanguageChange}
                        venueName={venueName}
                        venueAddress={venueAddress}
                        referrer={referrer}
                        language={language}
                        handleSecondaryLanguageChange={
                            handleSecondaryLanguageChange
                        }
                        secondaryLanguage={secondaryLanguage}
                        languages={languages}
                        ErrorMessage={ErrorMessage}
                        AddressErrorMessage={AddressErrorMessage}
                        showEventNext={showEventNext}
                    />
                }
            />

            <Route
                path={`/:section/:index/${Screens.talent}`}
                element={
                    <Talent
                        onBackClick={handleBackClick}
                        onNextClick={handleNextClick}
                        talentOptions={
                            serviceData[currentServiceObj.id]
                                ? serviceData[currentServiceObj.id]
                                      .filteredTalents
                                : []
                        }
                        service={serviceName}
                        step={step}
                        handleToggleTalent={handleToggleTalent}
                        selectedTalent={talent}
                        selectedPackages={
                            serviceData[currentServiceObj.id]
                                ? serviceData[currentServiceObj.id]
                                      .selectedPackages
                                : []
                        }
                        selectedEnhancements={
                            serviceData[currentServiceObj.id]
                                ? serviceData[currentServiceObj.id]
                                      .selectedEnhancements
                                : []
                        }
                        hours={hours}
                        enhancementValues={
                            serviceData[currentServiceObj.id]
                                ? serviceData[currentServiceObj.id]
                                      .enhancementValues
                                : []
                        }
                    />
                }
            />
            <Route
                path={`/:section/:index/${Screens.packages}`}
                element={
                    <ServicePackages
                        enabledPackages={
                            serviceData[process.env.REACT_APP_DJ_SERVICE]
                                .selectedPackages
                        }
                        onPackageClick={handlePackageToggle}
                        service={serviceName}
                        onBackClick={handleBackClick}
                        onNextClick={handleNextClick}
                        eventType={eventType}
                        step={step}
                        serviceId={process.env.REACT_APP_DJ_SERVICE}
                        onWeddingTypeChange={onWeddingTypeChange}
                        weddingType={weddingType}
                        onStartChange={handleStartTimeChange}
                        onEndChange={handleEndTimeChange}
                        isAMEndTime={isAMEndTime}
                    />
                }
            />
            <Route
                path={`/:section/:index/${Screens.booth}`}
                element={
                    <ServicePackages
                        enabledPackages={
                            serviceData[
                                process.env.REACT_APP_PHOTO_BOOTH_SERVICE
                            ].selectedPackages
                        }
                        onPackageClick={handlePackageToggle}
                        service={serviceName}
                        onBackClick={handleBackClick}
                        onNextClick={handleNextClick}
                        eventType={eventType}
                        step={step}
                        serviceId={process.env.REACT_APP_PHOTO_BOOTH_SERVICE}
                        onWeddingTypeChange={onWeddingTypeChange}
                        weddingType={weddingType}
                        onStartChange={handleStartTimeChange}
                        onEndChange={handleEndTimeChange}
                        isAMEndTime={isAMEndTime}
                    />
                }
            />
            <Route
                path={`/:section/:index/${Screens.photography}`}
                element={
                    <ServicePackages
                        enabledPackages={
                            serviceData[
                                process.env.REACT_APP_PHOTOGRAPHY_SERVICE
                            ].selectedPackages
                        }
                        onPackageClick={handlePackageToggle}
                        service={serviceName}
                        onBackClick={handleBackClick}
                        onNextClick={handleNextClick}
                        eventType={eventType}
                        step={step}
                        serviceId={process.env.REACT_APP_PHOTOGRAPHY_SERVICE}
                        onWeddingTypeChange={onWeddingTypeChange}
                        weddingType={weddingType}
                        onStartChange={handleStartTimeChange}
                        onEndChange={handleEndTimeChange}
                        isAMEndTime={isAMEndTime}
                    />
                }
            />
            <Route
                path={`/:section/:index/${Screens.videography}`}
                element={
                    <ServicePackages
                        enabledPackages={
                            serviceData[process.env.REACT_APP_VIDEO_SERVICE]
                                .selectedPackages
                        }
                        onPackageClick={handlePackageToggle}
                        service={serviceName}
                        onBackClick={handleBackClick}
                        onNextClick={handleNextClick}
                        eventType={eventType}
                        step={step}
                        serviceId={process.env.REACT_APP_VIDEO_SERVICE}
                        onWeddingTypeChange={onWeddingTypeChange}
                        weddingType={weddingType}
                        onStartChange={handleStartTimeChange}
                        onEndChange={handleEndTimeChange}
                        isAMEndTime={isAMEndTime}
                    />
                }
            />
            <Route
                path={`/:section/:index/${Screens.venue}`}
                element={
                    <ServicePackages
                        enabledPackages={
                            serviceData[process.env.REACT_APP_VENUE_SERVICE]
                                .selectedPackages
                        }
                        onPackageClick={handlePackageToggle}
                        service={serviceName}
                        onBackClick={handleBackClick}
                        onNextClick={handleNextClick}
                        eventType={eventType}
                        step={step}
                        serviceId={process.env.REACT_APP_VENUE_SERVICE}
                        onWeddingTypeChange={onWeddingTypeChange}
                        weddingType={weddingType}
                        onStartChange={handleStartTimeChange}
                        onEndChange={handleEndTimeChange}
                        isAMEndTime={isAMEndTime}
                    />
                }
            />
            <Route
                path={`/:section/:index/${Screens.product}`}
                element={
                    <Product
                        onProductClick={handleProductToggle}
                        enabledProducts={enabledProducts}
                        onBackClick={handleBackClick}
                        onNextClick={handleNextClick}
                        service={currentServiceObj}
                        step={step}
                        products={products}
                        onStartTimeChange={handleStartTimeChange}
                        onEndTimeChange={handleEndTimeChange}
                    />
                }
            />
            <Route
                path={`/:section/:index/${Screens.serviceEnhancements}`}
                element={
                    <ServiceEnhancements
                        selectedEnhancements={selectedEnhancements}
                        onEnhancementClick={handleEnhancementToggle}
                        onEnhancementChange={handleEnhancementChange}
                        onBackClick={handleBackClick}
                        onNextClick={handleNextClick}
                        options={
                            serviceData[currentServiceObj.id]
                                ? serviceData[currentServiceObj.id]
                                      .filteredOptions
                                : []
                        }
                        step={step}
                        service={serviceName}
                    />
                }
            />
            <Route
                path={`/:section/:index/${Screens.contact}`}
                element={
                    <ContactInfo
                        handleEmailChange={handleEmailChange}
                        handlePhoneChange={handlePhoneChange}
                        handleFirstChange={handleFirstChange}
                        handleLastChange={handleLastChange}
                        service={serviceName}
                        onNextClick={handleNextClick}
                        onBackClick={handleBackClick}
                        step={step}
                        ErrorMessage={ErrorMessage}
                    />
                }
            />
            <Route
                path={`/:section/:index/${Screens.quote}`}
                element={
                    <Quote
                        onBackClick={handleEditClick}
                        onBookClick={handleBookClick}
                        onRestartClick={handleRestartClick}
                        first={first}
                        last={last}
                        session={session}
                        refreshQuote={refreshQuote}
                    />
                }
            />
            <Route
                path={`/:section/:index/${Screens.contract}`}
                element={
                    <Contract
                        first={first}
                        last={last}
                        onNextClick={handleContractClick}
                        onBackClick={toQuoteClick}
                        quoteData={quoteData}
                    />
                }
            />
            <Route
                path={`/:section/:index/${Screens.payment}`}
                element={
                    <Payment
                        quoteData={quoteData}
                        onNextClick={handlePaymentDone}
                    />
                }
            />

            <Route
                path="/complete"
                element={
                    <Complete session={session} refreshQuote={refreshQuote} />
                }
            />
        </Routes>
    );
}

export default Quoting;
