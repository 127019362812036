import React from "react";

import styles from "./formStyling.module.css";

function GetStarted({ onStartClick, services }) {
    return (
        <>
            <div className={styles.wrap}>
                <h2>Quoting DEMO</h2>
                <p>Which service did the user select on the main site?</p>
                {services.map((service) => {
                    return (
                        <input
                            type="button"
                            value={service.service}
                            className={styles.getStartedButton}
                            onClick={() => onStartClick(service.id)}
                        />
                    );
                })}
            </div>
        </>
    );
}

export default GetStarted;
